@import '@studio/fonts';

*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  margin: inherit;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 16px;
  font-family: sans-serif;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  transition: background-color 100ms ease-in-out;
  opacity: 1;
}

#root {
  display: flex;
  flex-direction: column;
}
